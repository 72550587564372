<template>
  <v-app id="pikle">
    <v-main class="content-container">
      <v-container class="container">
        <h1 class="white--text mb-4">Pikle E-Commerce Knowledge Base</h1>
        <v-row justify="center">
          <div class="pricing-panel mb-6">
            <h2>How-to Articles</h2>
            <v-list>
              <v-list-item disabled class="info--text" :to="`/apps/${this.$route.params.appId}/docs`">
                Import Items into your Catalog
              </v-list-item>
              <v-list-item :to="`/apps/${this.$route.params.appId}/docs/taxons`">
                Set up a new Taxon
              </v-list-item>
              <v-list-item disabled class="info--text" :to="`/apps/${this.$route.params.appId}/docs`">
                Import Values
              </v-list-item>
              <v-list-item disabled class="info--text" :to="`/apps/${this.$route.params.appId}/docs`">
                Integrate & Enable Services
              </v-list-item>
            </v-list>
          </div>
          <div class="pricing-panel mb-6">
            <h2>Quick Watches</h2>
            <div class="d-flex flex-wrap">

              <v-card outlined class="ma-2 pa-2">
                <a href="https://www.loom.com/share/a79f44345c4b4d5d89f10244d6446d9a">
                  <p>How-two Generate Product Summaries! 🚀</p>
                </a>
                <a href="https://www.loom.com/share/a79f44345c4b4d5d89f10244d6446d9a">
                  <img style="max-width:260px;" src="https://cdn.loom.com/sessions/thumbnails/a79f44345c4b4d5d89f10244d6446d9a-23c4e315f858964b-full-play.gif">
                </a>
              </v-card>

              <v-card outlined class="ma-2 pa-2">
                <a href="https://www.loom.com/share/65f035d98bc24766af15798cc0b083e9">
                  <p>Managing Comparison Board Settings 👩‍💻</p>
                </a>
                <a href="https://www.loom.com/share/65f035d98bc24766af15798cc0b083e9">
                  <img style="max-width:260px;" src="https://cdn.loom.com/sessions/thumbnails/65f035d98bc24766af15798cc0b083e9-cde3a9308da3a02b-full-play.gif">
                </a>
              </v-card>

              <v-card outlined class="ma-2 pa-2">
                <a href="https://www.loom.com/share/d2ffad2d86ad4b75935490a4622b3101">
                  <p>Adding Feature Descriptions to Products 💡</p>
                </a>
                <a href="https://www.loom.com/share/d2ffad2d86ad4b75935490a4622b3101">
                  <img style="max-width:260px;" src="https://cdn.loom.com/sessions/thumbnails/d2ffad2d86ad4b75935490a4622b3101-bb638ceecd26a269-full-play.gif">
                </a>
              </v-card>

              <v-card outlined class="ma-2 pa-2">
                <a href="https://www.loom.com/share/54fb78635f1b4b3e9127ed50cb7955c8">
                  <p>Time Saving Import Settings ⏱️</p>
                </a>
                <a href="https://www.loom.com/share/54fb78635f1b4b3e9127ed50cb7955c8">
                  <img style="max-width:260px;" src="https://cdn.loom.com/sessions/thumbnails/54fb78635f1b4b3e9127ed50cb7955c8-1a66ca85ac50f666-full-play.gif">
                </a>
              </v-card>

              <v-card outlined class="ma-2 pa-2">
                <a href="https://www.loom.com/share/a151b02237b54f76b212c3be0fd84c51">
                  <p>New Approval Process Flows 📋</p>
                </a>
                <a href="https://www.loom.com/share/a151b02237b54f76b212c3be0fd84c51">
                  <img style="max-width:260px;" src="https://cdn.loom.com/sessions/thumbnails/a151b02237b54f76b212c3be0fd84c51-94a4df61ae5a21cb-full-play.gif">
                </a>
              </v-card>

            </div>
          </div>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'DocumentationIndex',
  components: {
  },
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content-container {
  background-image: linear-gradient(-20deg, rgba(0, 16, 160, 0.3) 30%, rgba(166, 0, 255, 0.8) 60%), url('~@/assets/homepage/galaxy.webp');
  background-size: cover;
  background-position: center;
  max-height: 400px;
  overflow-y: visible;
}

.container {
  margin: 0 auto;
  padding: 50px 0 100px 0;
  max-width: 960px;
  width: 100%;
}

.pricing-panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
  position: relative;
  width: 100%;
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
}
</style>
